<template>
	<S2SForm title="Update KYC">
		<v-form ref="form" v-if="kycUser">
			<v-card>
				<v-card-text>
					<v-row>
						<v-col cols="12" md="6">
							<v-text-field v-model="kycUser.firstName" label="Name" :rules="[v => !!v || 'Name is required']"></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field v-model="kycUser.lastName" label="Surname" :rules="[v => !!v || 'Surname is required']"></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" md="6">
							<v-text-field
								v-model="kycUser.email"
								label="Email"
								:rules="[v => !!v || 'Email is required', v => verifyEmail(v) || 'Email format incorrect']"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-row>
								<v-col>
									<v-select :items="items" v-model="kycData.idType" @change="updateLabel(kycData.idType)"></v-select>
								</v-col>
								<v-col>
									<v-text-field
										v-if="kycData.idType === 'rsa_id'"
										v-model="kycData.idNum"
										label="ID number"
										:rules="[v => !!v || 'ID number is required', v => /^(\d{13})?$/.test(v) || 'ID number 13 digits']"
									></v-text-field>
									<v-text-field v-else v-model="kycData.idNum" :label="label"></v-text-field>
								</v-col>
							</v-row>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" md="6">
							<v-text-field
								@click="dialogDOB = true"
								label="Date Of Birth"
								prepend-icon="event"
								:value="kycData.dob"
								readonly
								clearable
								@click:clear="kycData.dob = null"
								:rules="[v => !!v || 'Date Of Birth is required']"
							></v-text-field>
							<v-dialog ref="dialog" v-model="dialogDOB" persistent width="initial">
								<div>
									<v-layout row wrap class="white" px-2 pb-2>
										<v-flex mr-2>
											<h4 style="background-color: white" class="py-2 pl-2 mt-2 text-center">Date: {{ kycData.dob }}</h4>
											<v-date-picker v-model="kycData.dob" type="date" scrollable no-title :max="getMaxDate"></v-date-picker>
										</v-flex>
									</v-layout>

									<v-flex>
										<v-card color="background" height="50px">
											<v-btn text color="primary" @click="dialogDOB = false" block min-height="100%">Done</v-btn>
										</v-card>
									</v-flex>
								</div>
							</v-dialog>
						</v-col>
						<v-col cols="12" md="6">
							<v-select :items="genders" label="Gender" v-model="kycData.gender" :rules="[v => !!v || 'Gender is required']"></v-select>
						</v-col>
					</v-row>

					<v-row>
						<!-- ID -->
						<v-col cols="12" md="6" v-show="kycData.idImageUrl">
							<h3>Identification</h3>
							<div
								id="fileChangeID"
								:class="draggedOverID"
								v-cloak
								@drop.prevent="dropFile"
								@dragover.prevent
								@dragenter="changeClass('ID')"
								@dragleave="changeClass('ID')"
								@click="selectFile('fileInputID')"
							>
								<!-- PDF -->
								<div v-if="kycData.idImagePDF">
									<iframe :src="kycData.idImageUrl" frameborder="0" width="100%" height="300"></iframe>
								</div>

								<!-- IMAGE -->
								<div v-else>
									<v-img :src="kycData.idImageUrl" :lazy-src="kycData.idImageUrl" max-width="500" max-height="300" style="margin: auto">
										<template v-slot:placeholder>
											<v-row class="fill-height ma-0" align="center" justify="center">
												<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
											</v-row>
										</template>
									</v-img>
								</div>
							</div>
							<div v-if="kycData.fileID" class="text-center" align-baseline>
								<div class="subheading font-weight-medium">
									{{ kycData.fileID.name }}

									<span style="color: red" v-if="kycData.fileID.status === 'error'">{{ kycData.fileID.errorMessage }}</span>
									<span style="color: green" v-else>
										<span v-if="percentage > 0 && percentage < 100"> File Uploading {{ percentage }} % </span>
										<span v-if="percentage === 100">
											File Uploaded
										</span>
										<span v-else>Processing File</span>
									</span>
								</div>
							</div>
						</v-col>
						<v-col cols="12" md="6" v-show="!kycData.idImageUrl">
							<div
								id="fileUploadID"
								:class="[draggedOverID, hasIdFile]"
								v-cloak
								@drop.prevent="dropFile"
								@dragover.prevent
								@dragenter="changeClass('ID')"
								@dragleave="changeClass('ID')"
								@click="selectFile('fileInputID')"
							>
								<h3 class="text-center">Identification</h3>
								<div class="text-center">Drop file here or click to upload.</div>
								<input ref="fileInputID" id="fileInputID" style="visibility: hidden" type="file" @change="uploadFile" />
								<v-progress-linear v-if="uploadInProgressID" indeterminate color="accent"></v-progress-linear>
							</div>
							<div v-if="kycData.fileID" class="text-center" align-baseline>
								<div class="subheading font-weight-medium">
									{{ kycData.fileID.name }}

									<span style="color: red" v-if="kycData.fileID.status === 'error'">{{ kycData.fileID.errorMessage }}</span>
									<span style="color: green" v-else>
										<span v-if="percentage > 0 && percentage < 100"> File Uploading {{ percentage }} % </span>
										<span v-if="percentage === 100">
											File Uploaded
										</span>
										<span v-else>Processing File</span>
									</span>
								</div>
							</div>
						</v-col>

						<!-- SELFIE -->
						<v-col cols="12" md="6" v-show="kycData.selfieImageUrl">
							<h3>Selfie</h3>
							<div
								id="fileChangeSelfie"
								:class="draggedOverSelfie"
								v-cloak
								@drop.prevent="dropFile"
								@dragover.prevent
								@dragenter="changeClass('Selfie')"
								@dragleave="changeClass('Selfie')"
								@click="selectFile('fileInputSelfie')"
							>
								<!-- PDF -->
								<div v-if="kycData.selfieImagePDF">
									<iframe :src="kycData.selfieImageUrl" frameborder="0" width="100%" height="300"></iframe>
								</div>

								<!-- IMAGE -->
								<div v-else>
									<v-img
										:src="kycData.selfieImageUrl"
										:lazy-src="kycData.selfieImageUrl"
										max-width="500"
										max-height="300"
										style="margin: auto"
									>
										<template v-slot:placeholder>
											<v-row class="fill-height ma-0" align="center" justify="center">
												<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
											</v-row>
										</template>
									</v-img>
								</div>
							</div>
							<div v-if="kycData.fileSelfie" class="text-center" align-baseline>
								<div class="subheading font-weight-medium">
									{{ kycData.fileSelfie.name }}

									<span style="color: red" v-if="kycData.fileSelfie.status === 'error'">{{ kycData.fileSelfie.errorMessage }}</span>
									<span style="color: green" v-else>
										<span v-if="percentageAlt > 0 && percentageAlt < 100"> File Uploading {{ percentageAlt }} % </span>
										<span v-if="percentageAlt === 100">
											File Uploaded
										</span>
										<span v-else>Processing File</span>
									</span>
								</div>
							</div>
						</v-col>
						<v-col cols="12" md="6" v-show="!kycData.selfieImageUrl">
							<div
								id="fileUploadSelfie"
								:class="[draggedOverSelfie, hasSelfie]"
								v-cloak
								@drop.prevent="dropFile"
								@dragover.prevent
								@dragenter="changeClass('Selfie')"
								@dragleave="changeClass('Selfie')"
								@click="selectFile('fileInputSelfie')"
							>
								<h3 class="text-center">Selfie</h3>
								<div class="text-center">Drop file here or click to upload.</div>
								<input ref="fileInputSelfie" id="fileInputSelfie" style="visibility: hidden" type="file" @change="uploadFile" />
								<v-progress-linear v-if="uploadInProgressSelfie" indeterminate color="accent"></v-progress-linear>
							</div>
							<div v-if="kycData.fileSelfie" class="text-center" align-baseline>
								<div class="subheading font-weight-medium">
									{{ kycData.fileSelfie.name }}

									<span style="color: red" v-if="kycData.fileSelfie.status === 'error'">{{ kycData.fileSelfie.errorMessage }}</span>
									<span style="color: green" v-else>
										<span v-if="percentageAlt > 0 && percentageAlt < 100"> File Uploading {{ percentageAlt }} % </span>
										<span v-if="percentageAlt === 100">
											File Uploaded
										</span>
										<span v-else>Processing File</span>
									</span>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="cancel()">Cancel</v-btn>
					<v-btn class="accent" @click="submit()">Submit</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</S2SForm>
</template>

<script>
export default {
	name: "CreateKYC",

	props: {
		uid: {
			type: String,
			default: ""
		}
	},

	computed: {
		kycUser: function() {
			return this.$store.state.user.kycUser;
		},
		orgState: function() {
			return this.$store.state.user;
		},
		percentage() {
			return this.$store.state.files.percentage;
		},
		percentageAlt() {
			return this.$store.state.files.percentageAlt;
		},
		getMaxDate() {
			let date = new Date();
			let month = "" + date.getMonth();
			let day = "" + date.getDate();
			if (month.length < 2) {
				month = "0" + month;
			}
			if (day.length < 2) {
				day = "0" + day;
			}

			date = date.getFullYear() - 18 + `-${month}-${day}`;
			return date;
		}
	},

	watch: {
		kycUser(val) {
			this.kycData = {
				idNum: val.idNumber ? val.idNumber.idNumber : null,
				idType: val.idNumber ? val.idNumber.idType : "rsa_id",
				gender: val.gender ? val.gender : null,
				dob: val.dob ? val.dob : this.getMaxDate,
				idImagePDF: val.idImage.contentType === "application/pdf" ? true : false,
				idImageUrl: val.idImage && val.idImage.url ? `https://${val.idImage.url}` : null,
				selfieImagePDF: val.selfieImage.contentType === "application/pdf" ? true : false,
				selfieImageUrl: val.selfieImage && val.selfieImage.url ? `https://${val.selfieImage.url}` : null
			};
		}
	},

	data: function() {
		return {
			kycData: { idNum: null, fileID: null, fileSelfie: null, idType: "rsa_id", dob: null, gender: null },
			label: null,
			draggedOverID: "",
			draggedOverSelfie: "",
			uploadInProgressID: false,
			uploadInProgressSelfie: false,
			hasIdFile: "",
			hasSelfie: "",
			items: [
				{
					value: "rsa_id",
					text: "RSA ID"
				},
				{
					value: "passport",
					text: "Passport"
				},
				{
					value: "asylum",
					text: "Asylum"
				},
				{
					value: "other",
					text: "Other"
				}
			],
			genders: [
				{
					value: "male",
					text: "Male"
				},
				{
					value: "female",
					text: "Female"
				}
			],
			dialogDOB: false,
			currentFile: null
		};
	},

	mounted: function() {
		const uid = this.$props.uid;

		this.$store.dispatch("files/clearPercentage");
		this.$store.dispatch("files/clearAltPercentage");

		if (uid === "") this.$router.push({ name: "verify-kyc" });

		this.$store.dispatch("user/getKYCUser", uid);
	},

	methods: {
		selectFile(inputId) {
			document.getElementById(inputId).click();
		},

		async uploadFile(e) {
			if (e.target.id === "fileInputID") {
				this.draggedOverID = "selected";
				const file = await e.target.files[0];
				this.kycData.fileID = file;
			} else {
				this.draggedOverSelfie = "selected";
				const file = await e.target.files[0];
				this.kycData.fileSelfie = file;
			}
		},
		async dropFile(e) {
			if (e.toElement.id === "fileUploadID" || this.currentFile === "ID") {
				const file = await e.dataTransfer.files[0];
				this.kycData.fileID = file;
			} else {
				const file = await e.dataTransfer.files[0];
				this.kycData.fileSelfie = file;
			}
		},
		changeClass(file) {
			this.currentFile = file;

			if (file === "ID" || file === "fileUploadID") {
				if (this.draggedOverID === "selected") {
					this.draggedOverID = "selected";
				} else if (this.draggedOverID === "dragging") {
					this.draggedOverID = "";
				} else {
					this.draggedOverID = "dragging";
				}
			} else {
				if (this.draggedOverSelfie === "selected") {
					this.draggedOverSelfie = "selected";
				} else if (this.draggedOverSelfie === "dragging") {
					this.draggedOverSelfie = "";
				} else {
					this.draggedOverSelfie = "dragging";
				}
			}
		},

		verifyEmail(email) {
			return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email
			);
		},

		async submit() {
			if (this.$refs.form.validate()) {
				this.uploadInProgressID = true;
				this.uploadInProgressSelfie = true;

				this.hasIdFile = "";
				this.hasSelfie = "";

				this.kycData.kycUser = this.kycUser;

				const success = await this.$store.dispatch("user/updateKYCData", { uid: this.$props.uid, kycData: this.kycData });

				if (success.files.ID.status === 200 && success.files.Selfie.status === 200) {
					this.$store.dispatch("notifications/success", "KYC updated successfully.", { root: true });
					this.uploadInProgressID = false;
					this.uploadInProgressSelfie = false;
					this.$store.dispatch("user/getKYCUser", this.$props.uid);
				} else {
					this.$store.dispatch("notifications/error", `KYC not updated. ${success}`, { root: true });
				}

				this.$router.push({ name: "verify-kyc" });
			} else {
				this.hasIdFile = !this.kycData.fileID ? "no-file" : "";
				this.hasSelfie = !this.kycData.fileSelfie ? "no-file" : "";
			}
		},

		updateLabel(val) {
			const item = this.items.filter(el => el.value === val);
			this.label = item[0].text + " number";
		},

		cancel() {
			this.$router.push({ name: "verify-kyc" });
		}
	}
};
</script>

<style lang="scss" scoped>
#fileUploadID,
#fileUploadSelfie {
	height: 20vh;
	border: 2px dashed #ccc;
	padding: 10px;
	border-radius: 5px;

	&.dragging {
		background: #e1f5fe repeating-linear-gradient(-45deg, #fafafa 25%, transparent 25%, transparent 50%, #fafafa 50%, #fafafa 75%);
		background-size: 40px 40px;
	}

	&.selected {
		background: #e1f5fe repeating-linear-gradient(-45deg, #fafafa 25%, transparent 25%, transparent 50%, #fafafa 50%, #fafafa 75%);
		background-size: 40px 40px;
		border-color: #faa225;
	}

	&.no-file {
		border-color: red;
	}
}

#fileChangeID,
#fileChangeSelfie {
	border: 2px dashed #ccc;
	padding: 10px;
	border-radius: 5px;
	cursor: pointer;

	&.dragging {
		background: #e1f5fe repeating-linear-gradient(-45deg, #fafafa 25%, transparent 25%, transparent 50%, #fafafa 50%, #fafafa 75%);
		background-size: 40px 40px;
		border-color: #faa225;
	}

	&.selected {
		background: #e1f5fe repeating-linear-gradient(-45deg, #fafafa 25%, transparent 25%, transparent 50%, #fafafa 50%, #fafafa 75%);
		background-size: 40px 40px;
		border-color: #faa225;
	}
}
</style>
